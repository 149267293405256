import BigNumber from "bignumber.js";

// Token balance'ına ondalık eklemek için
export function removeDecimal(balance, decimal, fixed) {
  if (Number(balance) > 0) {
    if (decimal > 0) {
      const balanceBig = new BigNumber(String(balance));
      const decimalBig = new BigNumber(String(10)).exponentiatedBy(
        String(decimal)
      );
      const result = new BigNumber(balanceBig.dividedBy(decimalBig));
      return result.toFixed(fixed);
    } else {
      const balanceBig = new BigNumber(String(balance));
      return balanceBig.toFixed(fixed);
    }
  } else {
    return "0";
  }
}

// Token balance'ından ondalık çıkarmak için
export function addDecimal(balance, decimal, fixed) {
  if (Number(balance) > 0) {
    if (decimal > 0) {
      const balanceBig = new BigNumber(String(balance));
      const decimalBig = new BigNumber(String(10)).exponentiatedBy(
        String(decimal)
      );
      const result = new BigNumber(balanceBig.multipliedBy(decimalBig));
      return result.toFixed(fixed);
    } else {
      const balanceBig = new BigNumber(String(balance));
      return balanceBig.toFixed(fixed);
    }
  } else {
    return "0";
  }
}
