import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/post/post-recent-new-4.jpg";
import img2 from "../../assets/images/post/post-recent-new-5.jpg";

const Footer = () => {
  const [dataSocial] = useState([
    {
      icon: "fab fa-telegram",
      to: "https://t.me/Joops_RWA",
    },
    {
      icon: "fab fa-github",
      to: "https://github.com/Joops-RWA?tab=repositories",
    },
    {
      icon: "fab fa-medium",
      to: "https://medium.com/@Joops_RWA",
    },
    {
      icon: "fab fa-twitter",
      to: "https://twitter.com/Joopsrwa",
    },
    {
      icon: "far fa-envelope-open",
      to: "mailto:Info@joops.co.uk",
    },
  ]);

  const [dataLinkMarket] = useState([
    {
      title: "JOOPS",
      link: "https://www.joops.app/",
    },
    {
      title: "Pitch Deck",
      link: "https://my.visme.co/view/90rpgopp-6ep5dwpvregk2dz3#s1",
    },
    {
      title: "Whitepaper",
      link: "https://my.visme.co/view/kkjwe3g3-m3x58k1eo73v5krp#s1",
    },
    {
      title: "Blog",
      link: "https://medium.com/@Joops_RWA",
    },
    {
      title: "Airdrop",
      link: "https://www.joops.app/airdrop",
    },
  ]);

  const [dataSupport] = useState([
    {
      title: "Privacy Policy",
      link: "https://www.joops.app/privacy-policy",
    },
    {
      title: "Terms & Conditions",
      link: "https://www.joops.app/terms-conditions",
    },
    {
      title: "Cookie Policy",
      link: "https://www.joops.app/cookie-policy",
    },
  ]);

  const [dataRecent] = useState([
    {
      img: img1,
      title: "Roll Out New Features Without Hurting Loyal Users",
      time: "25 JAN 2022",
    },
    {
      img: img2,
      title: "An Overview The Most Comon UX Design Deliverables",
      time: "25 JAN 2022",
    },
  ]);

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <div>
      <footer id="footer" className="clearfix bg-style">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="widget widget-logo">
                <div className="logo-footer" id="logo-footer">
                  <Link to="/">
                    <img
                      id="logo_footer"
                      src={"/assets/logo.webp"}
                      alt="nft-gaming"
                    />
                  </Link>
                </div>
                <p className="sub-widget-logo">
                  THE MEME WITH A DREAM TO CONNECT THE WORLD THROUGH R.W.A.
                </p>
                <div className="widget-social">
                  <ul>
                    {dataSocial.map((item, index) => (
                      <li key={index}>
                        <a href={item.to} target="_blank">
                          <i className={item.icon}></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="widget widget-menu menu-marketplace">
                <h5 className="title-widget">Menu</h5>
                <ul>
                  {dataLinkMarket.map((item, index) => (
                    <li key={index}>
                      <a href={item.link} target="_blank">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <div className="widget widget-menu menu-supports">
                <h5 className="title-widget">Legal</h5>
                <ul>
                  {dataSupport.map((item, index) => (
                    <li key={index}>
                      <a href={item.link} target="_blank">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget widget-post">
                <h5 className="title-widget">Coming Soon</h5>
                <a href="https://joops.app">
                  <img src="/assets/appstore.webp"></img>
                </a>
                <a href="https://joops.app">
                  <img src="/assets/playstore.webp"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottom">
        <div className="container">
          <div className="bottom-inner">
            © 2025 by
            <a href="https://joops.app"> JOOPS</a>
          </div>
        </div>
      </div>
      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </div>
  );
};

export default Footer;
