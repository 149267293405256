const menus = [
  {
    id: 1,
    name: "JOOPS",
    links: "https://www.joops.app",
  },
  {
    id: 2,
    name: "Pitch Deck",
    links: "https://my.visme.co/view/90rpgopp-6ep5dwpvregk2dz3#s1",
  },
  {
    id: 3,
    name: "Whitepaper",
    links: "https://my.visme.co/view/kkjwe3g3-m3x58k1eo73v5krp#s1",
  },
  {
    id: 4,
    name: "Blog",
    links: "https://medium.com/@Joops_RWA",
  },
  {
    id: 5,
    name: "Airdrop",
    links: "https://www.joops.app/airdrop",
  },
];

export default menus;
