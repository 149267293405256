import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function WarningModal({ show, setShow, warningModalContent }) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { modalHeading, modalBody, buttonName, onClick } = warningModalContent;
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mt-3 mb-3">
            <h4>{modalHeading}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-5 mb-5">{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onClick}>
            {buttonName}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WarningModal;
